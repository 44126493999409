import React from 'react';

const ButtonPrimary = ({ btnTitle }) => {
    //shadow-lg shadow-[#FF1B6C]/75
    return (
        // <button className={`relative rounded-lg px-4 sm:px-6 py-2 sm:py-2.5 overflow-hidden group ${isPrimary ? "bg-primary-light text-white hover:ring-1 hover:ring-offset-1 hover:ring-primary-light" : "text-white border hover:bg-white hover:text-black"} transition-all ease-out duration-300`} aria-label='Button'>
        <button className={`relative text-white rounded-lg px-4 sm:px-6 py-2 sm:py-2.5 overflow-hidden group bg-gradient-to-tl to-[#990036]/100 from-[#FF1B6C]/80 transition-all ease-out duration-300`} aria-label='Button'>
            <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-60 ease"></span>
            <span className="relative text-[13px] sm:text-[15px] font-[500]">{btnTitle}</span>
        </button>
    );
};

export default ButtonPrimary;