import React from 'react';
import { container, navItem, navListHover, activeNavItem } from "../../styles/styles"; // navListItem, 
import { Link } from 'gatsby';
import './navigation.css';
import { navbarItems } from '../../constants';
import { useLocation } from '@reach/router';
import Logosvg from '../ui/Logosvg';

const Navbar = () => {
    const location = useLocation();
    const [isScrolled, setIsScrolled] = React.useState(false);
    const [smallScreen, setSmallScreen] = React.useState(false);
    const [isHomeScreen, setIsHomeScreen] = React.useState(true);
    const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false);
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    React.useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < 768) {
                setSmallScreen(true);
            }
            else {
                setSmallScreen(false);
            }
        };

        if (typeof window !== 'undefined') {
            handleResize();
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    React.useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
            if (containsOnlySlash(location?.pathname) || location?.pathname.includes("industries/government") || location?.pathname.includes("industries/military") || location?.pathname.includes("/partners") || location?.pathname.includes("/leadership") || location?.pathname.includes("/industries/finance")) {
                if (window.scrollY > 0) {
                    document.querySelector('#navigationBar').classList.add('onScrollNav');
                }
                else {
                    document.querySelector('#navigationBar').classList.remove('onScrollNav');
                }
            }
        };

        // Check if window object exists before adding event listener
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [location.pathname]);

    function containsOnlySlash(str) {
        return /^\/+$/.test(str);
    }

    React.useEffect(() => {
        if (containsOnlySlash(location?.pathname) || location?.pathname.includes("industries/government") || location?.pathname.includes("industries/military") || location?.pathname.includes("/partners") || location?.pathname.includes("/leadership") || location?.pathname.includes("/industries/finance")) {
            setIsHomeScreen(true);
        }
        else {
            setIsHomeScreen(false);
        }
    }, [location.pathname]);

    return (
        <div>
            {/* further submenu */}
            {/* <li className="pl-0 sm:pl-3 pr-3 py-2 cursor-pointer relative">
                <button className="w-full text-left flex items-center outline-none focus:outline-none">
                    <span className="pr-1 flex-1">Submenu 3</span>
                    <span className="mr-auto">
                        <svg
                            className="fill-current h-4 w-4 transform transition duration-150 ease-in-out"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                        >
                            <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256" />
                        </svg>
                    </span>
                </button>
            </li>; */}
            <nav id='navigationBar' className={`${smallScreen ? "smallScreenNav" : ""} border-gray-200 fixed top-0 left-0 right-0 transition-all ease-in-out duration-200 z-[999] h-fit w-full bg-opacity-30 py-1 ${!isHomeScreen ? "onScrollNav" : ""}`}>
                <div className={`${container} flex flex-wrap items-center justify-between mx-auto py-4 px-8`}>
                    {/* LOGO */}
                    <Link to="/" onClick={() => {
                        setMobileMenuOpen(false);
                    }} className="flex items-center space-x-3 rtl:space-x-reverse transition-all ease-in-out">
                        <Logosvg width={isScrolled ? '84' : '95'} height={isScrolled ? '34' : '45'} color={isScrolled ? "#252422" : (!isHomeScreen ? "#252422" : (smallScreen ? "#252422" : '#ffffff'))} />
                    </Link>
                    {/* mobile hamburger menu */}
                    <div className="xl:hidden block flex xl:order-2 space-x-3 xl:space-x-0 rtl:space-x-reverse">
                        <button data-collapse-toggle="navbar-cta" onClick={() => toggleMobileMenu()} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg xl:hidde2 cursor-pointer focus:outline-none text-gray-400" aria-controls="navbar-cta" aria-expanded={isMobileMenuOpen ? 'true' : 'false'}>
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                            </svg>
                        </button>
                    </div>

                    {/* list */}
                    <div className={`items-center justify-between w-full xl:flex xl:w-auto xl:order-1 transition-all ease-in-out ${isMobileMenuOpen ? "" : "hidden"}`} id="navbar-cta">
                        <ul className="flex flex-col font-medium p-4 xl:p-0 mt-4 rounded-lg xl:space-x-8 rtl:space-x-reverse xl:flex-row xl:mt-0 transition-all ease-in-out float-none sm:float-left">
                            <li>
                                <Link onClick={() => {
                                    setMobileMenuOpen(false);
                                }} to="/" activeClassName={`${activeNavItem}`} className={`w-fit ${containsOnlySlash(location?.pathname) ? "" : `${navListHover} pb-[0.7rem]`} ${navItem} block !py-3 pl-0 sm:pl-3 pr-3 xl:p-0 ${isScrolled ? "text-black" : (smallScreen ? "text-black" : "text-white")} ${!isHomeScreen ? "!text-black" : ""} text-[14px]`}>Home</Link>
                            </li>
                            {navbarItems?.map((items, index) => {
                                return (
                                    <>
                                        <li key={index} className={`${navItem} py-2 group ${location?.pathname?.includes(items?.listItemName?.toLocaleLowerCase()) ? activeNavItem : `${navListHover} pb-[0.7rem]`}`}>
                                            <div className="inline-block relative">
                                                <button className={`block py-2 pl-0 sm:pl-3 pr-3 xl:p-0 ${isScrolled ? "text-black" : (smallScreen ? "text-black" : "text-white")} ${!isHomeScreen ? "!text-black" : ""} text-[14px]`}>
                                                    <span className="pr-1 font-medium flex-1">{items.listItemName}</span>
                                                </button>
                                                {/* list items */}
                                                <ul className="shadow bg-white text-[14px] p-2 transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top right-0 left-0 min-w-[200px] w-[100%] mt-3 z-10">
                                                    {items?.listItems?.map((subItems, i) => {
                                                        return (
                                                            <>
                                                                {/* sub menu with no children */}
                                                                {/* <li className="pl-0 sm:pl-3 pr-3 py-2 cursor-pointer">Finance</li> */}
                                                                <li className="relative pl-0 sm:pl-3 pr-3 py-2 cursor-pointer submenu" key={i}>
                                                                    <Link to={subItems.link} onClick={() => toggleMobileMenu()} className="w-full text-left flex items-center outline-none focus:outline-none">
                                                                        <span className="pr-1 flex-1">{subItems?.itemName}</span>
                                                                        <span className="mr-auto">
                                                                            <svg
                                                                                className="fill-current h-4 w-4 transform transition duration-150 ease-in-out"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 512 512" >
                                                                                <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256" />
                                                                            </svg>
                                                                        </span>
                                                                    </Link>
                                                                    {/* <ul className="shadow bg-white absolute top-0 left-full mt-0 transition duration-150 ease-in-out origin-top min-w-[150px] w-[100%]">
                                                                        {subItems?.submenuItems?.map((x, y) => {
                                                                            return (<>
                                                                                <li key={y} className="pl-0 sm:pl-3 pr-3 py-2 cursor-pointer">{x.submenuItemName}</li>
                                                                            </>);
                                                                        })}
                                                                    </ul> */}
                                                                </li>
                                                            </>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        </li>
                                    </>
                                );
                            })}
                            <li>
                                <Link onClick={() => {
                                    setMobileMenuOpen(false);
                                }} to="/partners" activeClassName={`${activeNavItem}`} className={`w-fit ${location?.pathname?.includes("partners") ? "" : `${navListHover} pb-[0.7rem]`} ${navItem} block !py-3 pl-0 sm:pl-3 pr-3 xl:p-0 ${isScrolled ? "text-black" : (smallScreen ? "text-black" : "text-white")} ${!isHomeScreen ? "!text-black" : ""} text-[14px]`}>Partners</Link>
                            </li>
                            <li>
                                <Link onClick={() => {
                                    setMobileMenuOpen(false);
                                }} to="/leadership" activeClassName={`${activeNavItem}`} className={`w-fit ${location?.pathname?.includes("leadership") ? "" : `${navListHover} pb-[0.7rem]`} ${navItem} block !py-3 pl-0 sm:pl-3 pr-3 xl:p-0 ${isScrolled ? "text-black" : (smallScreen ? "text-black" : "text-white")} ${!isHomeScreen ? "!text-black" : ""} text-[14px]`}>Leadership</Link>
                            </li>
                            <li>
                                <Link onClick={() => {
                                    setMobileMenuOpen(false);
                                }} to="/contact-us" activeClassName={`${activeNavItem}`} className={`w-fit ${location?.pathname?.includes("contact-us") ? "" : `${navListHover} pb-[0.7rem]`} ${navItem} block !py-3 pl-0 sm:pl-3 pr-3 xl:p-0 ${isScrolled ? "text-black" : (smallScreen ? "text-black" : "text-white")} ${!isHomeScreen ? "!text-black" : ""} text-[14px]`}>Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;