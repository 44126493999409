export const container = 'container xl:max-w-[1250px] lg:max-w-[1000px] xmd:max-w-[800px] md:max-w-[690px] sm:max-w-[510px] xs:max-w-[80%] mx-auto xs:max-w-none sm:max-w-none xmd:max-w-none md:max-w-none lg:max-w-screen-lg';

// image styles
export const imageStyle = 'w-[160px] h-auto mt-5';

// paragraph / heading styles
export const fontStyle = "mx-1 rounded md:p-0 font-normal";
export const fontColorOnWhite = "text-primary-lighter";
export const responsiveParagraph = 'text-[13px] sm:text-[15px] leading-7 text-start md:text-center';
export const responsiveHeading = 'font-semibold text-2xl sm:text-3xl md:text-4xl text-start md:text-center tracking-wide';
export const responsiveSubHeading = 'font-normal text-md sm:text-xl text-start md:text-center';
export const headingWithBorder = "border border-[#CECECE] py-3 px-5 rounded-full tracking-[.5em] text-[14px] block w-fit mb-4";

// navbar styles
export const navListItem = "block text-[12px] sm:text-[14px] border-b border-gray-100 py-3 px-5 font-semibold hover:font-medium w-[160px] sm:w-[200px] text-gray-600 hover:text-white hover:bg-primary-light transition-all";
export const navListHover = "relative after:absolute after:bg-primary-light after:bottom-0 after:left-0 after:h-[2.5px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300";
export const navItem = "transition-all self-start xl:self-center";
export const activeNavItem = "!border-b-[3px] !pb-2 !border-primary-light";

// general
export const buttonPrimary = "cursor-pointer w-fit transition ease-in-out delay-150 text-white bg-primary-light hover:bg-primary-light_hover focus:outline-none font-medium rounded-full text-sm px-6 py-3 text-center hidden sm:block";
export const socialCircle = "rounded-full flex items-center justify-center h-[35px] w-[35px] border border-[#D2D2D2] bg-[#0d0d0d] m-3";

// usecases tabs
export const tabs = "inline-block border-transparent rounded-t-lg rounded-t-lg";

export const activeTab = "active border-primary-light border-l-4 sm:border-l-0 sm:border-b-2";