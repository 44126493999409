import React from 'react';

const Logosvg = ({ width, height, color }) => {
    return (
        <div>
            <svg className={`logo transition-all ease-in-out`} width={width} height={height} viewBox="0 0 84 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.42 9.29C11.3253 9.06764 11.1681 8.8775 10.9676 8.74261C10.767 8.60772 10.5317 8.53387 10.29 8.53H6.29C6.18494 8.52404 6.07999 8.54367 5.98419 8.58722C5.88839 8.63076 5.80459 8.69692 5.74 8.78C5.68655 8.87457 5.65845 8.98136 5.65845 9.09C5.65845 9.19864 5.68655 9.30543 5.74 9.4L8.47 14.13L4.17 21.72C4.11355 21.8135 4.08371 21.9207 4.08371 22.03C4.08371 22.1393 4.11355 22.2465 4.17 22.34C4.22365 22.429 4.30022 22.5019 4.39169 22.5511C4.48317 22.6004 4.58619 22.6242 4.69 22.62H8.69C8.92972 22.6125 9.16192 22.5345 9.35756 22.3958C9.55321 22.2571 9.70362 22.0637 9.79 21.84L14.15 14.13L11.42 9.29ZM27.83 3.88C27.8816 3.78483 27.9087 3.67827 27.9087 3.57C27.9087 3.46173 27.8816 3.35517 27.83 3.26C27.7709 3.17535 27.6912 3.10709 27.5985 3.06162C27.5059 3.01614 27.4031 2.99494 27.3 3H23.22C22.9839 3.00667 22.7551 3.08345 22.5628 3.22058C22.3704 3.3577 22.2233 3.54896 22.14 3.77C22.14 3.77 13.44 19.2 13.14 19.7L18.88 30.23C18.9736 30.452 19.1288 30.6425 19.3273 30.779C19.5258 30.9154 19.7592 30.9921 20 31H24C24.105 31.0088 24.2105 30.9894 24.3055 30.9437C24.4004 30.8979 24.4814 30.8276 24.54 30.74C24.5944 30.6457 24.6231 30.5388 24.6231 30.43C24.6231 30.3212 24.5944 30.2143 24.54 30.12L18.85 19.72L27.83 3.88Z" fill={color} />
                <path d="M50.5 27L47.975 18.325L45.475 27H42L38.2 13.65H41.575L43.85 22.1L46.3 13.65H49.725L52.1 22.1L54.35 13.65H57.775L53.95 27H50.5ZM71.3324 27L69.9824 23.15H63.0074L61.6324 27H57.8074L64.5574 9.05H68.7074L75.4074 27H71.3324ZM66.5324 13.2L64.0824 20.125H68.9324L66.5324 13.2ZM81.3941 27H77.5941V9.05H81.3941V27Z" fill={color} />
            </svg>
        </div>
    );
};

export default Logosvg;