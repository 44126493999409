import React from "react";
import BankAlfalah from "../assets/images/waiForYourEnterprise/alfalah.png";
import BankAlfalahImage from "../assets/images/waiForYourEnterprise/alfalahDigitalBranch.png";
import FBR from "../assets/images/waiForYourEnterprise/fbr.png";
import FBRImage from "../assets/images/waiForYourEnterprise/fbrImage.png";
import FBRAnotherImage from "../assets/images/waiForYourEnterprise/fbrAnotherImage.jpg";
import MinistryOfRailways from "../assets/images/waiForYourEnterprise/ministryRailways.png";
import MinistryOfRailwaysImage from "../assets/images/waiForYourEnterprise/mrImage.png";
import Finja from "../assets/images/waiForYourEnterprise/finja.png";
import FinjaImage from "../assets/images/waiForYourEnterprise/finjaImage.png";
import AskariBank from "../assets/images/waiForYourEnterprise/askaribank.png";
import AskariBankImage from "../assets/images/waiForYourEnterprise/askariBankImage.png";
import GenesysLogo from "../assets/images/waiForYourEnterprise/genesysLogo.png";
import UsecaseImage from "../assets/images/useCase.jpg";
import CustomChatbot from "../assets/images/Custom Chatbots for Enterprise Data.png";
import SpeechBot from "../assets/images/Advanced Speech Bots for App Functions.png";
import AiPowerd from "../assets/images/AI-Powered Document Handling.png";
import CCTV from "../assets/images/CCTV Analytics for FMCGs.png";
import OCR from "../assets/images/Digital Customer Onboarding with OCR.png";
import AssetPerformance from "../assets/images/Asset Performance Management.png";
import QulityControl from "../assets/images/Quality Control and Assurance.png";
import ManufacturingOpti from "../assets/images/Manufacturing Optimizations.png";
import SupplyChain from "../assets/images/Supply Chain Management.png";
import PredictiveMaintainence from "../assets/images/Predictive Maintenance.png";
import EnergyManagement from "../assets/images/Energy Management.png";
import RiskManagement from "../assets/images/Risk Management and Compliance.png";
import CRM from "../assets/images/Customer Relationship Management (CRM).png";
import ProcessOptimization from "../assets/images/Process Optimization.png";
import HRM from "../assets/images/Human Resources Management.png";
import StrategicAI from "../assets/images/Strategic AI Roadmap Development.jpg";
import DataStrategy from "../assets/images/Data Strategy and Governance.png";
import AIPerformance from "../assets/images/AI Performance Monitoring and Optimization.png";
import AIModel from "../assets/images/AI Model Development and Deployment.png";
import AiEthics from "../assets/images/AI Ethics and Responsible AI.png";
import AiTalent from "../assets/images/AI Talent Development and Training.png";
import AIRisk from "../assets/images/AI Risk Assessment and Management.png";
import AIIntegrations from "../assets/images/AI Integration and Implementation.png"
import AIROI from "../assets/images/AI ROI Analysis and Value Measurement.png";
import AIMarket from "../assets/images/AI Market Analysis and Opportunity Identification.png";
import CSquare from "../assets/images/csquare.png";
import ISSM from "../assets/images/issm.png";
import Workcycle from "../assets/images/workcycle.png";
import Novu from "../assets/images/novu.png";
import Plus21 from "../assets/images/plus21.png";
import Eocean from "../assets/images/eocean.png";
import Alasila from "../assets/images/alasila.png";
import Ase from "../assets/images/ase.png";
import Rototype from "../assets/images/rototype.png";
import Touchpoint from "../assets/images/Touchpoint.png";
import TPS from "../assets/images/tps.png";
import ExoScale from "../assets/images/exoscale.png";
import DigitalEye from "../assets/images/digitalEye.jpg";
import Alara from "../assets/images/alara.jpg";
import DigitalTwin from "../assets/images/digitalTwin.jpg";
import DigitalEye2 from "../assets/images/digitalEye2.jpg";
import SRA from "../assets/images/sra.jpg";
import Ahsan from "../assets/images/ahsan.jpg";
import Ashar from "../assets/images/ashar.jpg";
import Wahab from "../assets/images/wahab.jpg";
import Kwsb from "../assets/images/kwsb.png";
import Ke from "../assets/images/ke.png";
import Dfd from "../assets/images/dfd.png";

export const navbarItems = [
  {
    listItemName: "Industries",
    listItems: [
      {
        itemName: "Finance",
        link: "/industries/finance",
        breadcrumbs: "Industries / Finance",
        submenuItems: [
          { submenuItemName: "Submenu Item 1" },
          { submenuItemName: "Submenu Item 2" },
          { submenuItemName: "Submenu Item 3" },
          { submenuItemName: "Submenu Item 4" },
        ],
      },
      {
        itemName: "Government",
        link: "/industries/government",
        breadcrumbs: "Industries / Government",
        submenuItems: [
          { submenuItemName: "Submenu Item 1" },
          { submenuItemName: "Submenu Item 2" },
          { submenuItemName: "Submenu Item 3" },
          { submenuItemName: "Submenu Item 4" },
        ],
      },
      // {
      //     itemName: "Military",
      //     link: "/industries/military",
      //     breadcrumbs: "Industries / Government",
      //     submenuItems: [
      //         { submenuItemName: "Submenu Item 1" },
      //         { submenuItemName: "Submenu Item 2" },
      //         { submenuItemName: "Submenu Item 3" },
      //         { submenuItemName: "Submenu Item 4" },
      //     ],
      // },
    ],
  },
  // {
  //     listItemName: "Capabilities",
  //     listItems: [{
  //         itemName: "ML Development",
  //         link: "/capabilities/ml-development",
  //         breadcrumbs: "Capabilities / ML Development",
  //         submenuItems: [{ submenuItemName: "Submenu Item 1" },
  //         { submenuItemName: "Submenu Item 2" },
  //         { submenuItemName: "Submenu Item 3" },
  //         { submenuItemName: "Submenu Item 4" },]
  //     }, {
  //         itemName: "AI Consultancy",
  //         link: "/capabilities/ai-consultancy",
  //         breadcrumbs: "Capabilities / AI Consultancy",
  //         submenuItems: [{ submenuItemName: "Submenu Item 1" },
  //         { submenuItemName: "Submenu Item 2" },
  //         { submenuItemName: "Submenu Item 3" },
  //         { submenuItemName: "Submenu Item 4" },]
  //     }, {
  //         itemName: "Staff Augmentation",
  //         link: "/capabilities/staff-augmentation",
  //         breadcrumbs: "Capabilities / Staff Augmentation",
  //         submenuItems: [{ submenuItemName: "Submenu Item 1" },
  //         { submenuItemName: "Submenu Item 2" },
  //         { submenuItemName: "Submenu Item 3" },
  //         { submenuItemName: "Submenu Item 4" },]
  //     }]
  // },
  // {
  //     listItemName: "Resources",
  //     listItems: [{
  //         itemName: "Newsroom",
  //         link: "/resources/newsroom",
  //         breadcrumbs: "Resources / Newsroom",
  //         submenuItems: [{ submenuItemName: "Submenu Item 1" },
  //         { submenuItemName: "Submenu Item 2" },
  //         { submenuItemName: "Submenu Item 3" },
  //         { submenuItemName: "Submenu Item 4" },]
  //     }, {
  //         itemName: "White Papers",
  //         link: "/resources/white-papers",
  //         breadcrumbs: "Resources / White Papers",
  //         submenuItems: [{ submenuItemName: "Submenu Item 1" },
  //         { submenuItemName: "Submenu Item 2" },
  //         { submenuItemName: "Submenu Item 3" },
  //         { submenuItemName: "Submenu Item 4" },]
  //     }, {
  //         itemName: "Events",
  //         link: "/resources/events",
  //         breadcrumbs: "Resources / Events",
  //         submenuItems: [{ submenuItemName: "Submenu Item 1" },
  //         { submenuItemName: "Submenu Item 2" },
  //         { submenuItemName: "Submenu Item 3" },
  //         { submenuItemName: "Submenu Item 4" },]
  //     }, {
  //         itemName: "Blogs",
  //         link: "/resources/blogs",
  //         breadcrumbs: "Resources / Blogs",
  //         submenuItems: [{ submenuItemName: "Submenu Item 1" },
  //         { submenuItemName: "Submenu Item 2" },
  //         { submenuItemName: "Submenu Item 3" },
  //         { submenuItemName: "Submenu Item 4" },]
  //     }, {
  //         itemName: "Case Studies",
  //         link: "/resources/case-studies",
  //         breadcrumbs: "Resources / Case Studies",
  //         submenuItems: [{ submenuItemName: "Submenu Item 1" },
  //         { submenuItemName: "Submenu Item 2" },
  //         { submenuItemName: "Submenu Item 3" },
  //         { submenuItemName: "Submenu Item 4" },]
  //     }]
  // },
  // {
  //     listItemName: "Company",
  //     listItems: [{
  //         itemName: "Team",
  //         link: "/company/team",
  //         breadcrumbs: "Company / Team",
  //         submenuItems: [{ submenuItemName: "Submenu Item 1" },
  //         { submenuItemName: "Submenu Item 2" },
  //         { submenuItemName: "Submenu Item 3" },
  //         { submenuItemName: "Submenu Item 4" },]
  //     }, {
  //         itemName: "Partners",
  //         link: "/company/partners",
  //         breadcrumbs: "Company / Partners",
  //         submenuItems: [{ submenuItemName: "Submenu Item 1" },
  //         { submenuItemName: "Submenu Item 2" },
  //         { submenuItemName: "Submenu Item 3" },
  //         { submenuItemName: "Submenu Item 4" },]
  //     }]
  // }
];

export const aboutUsData = [
  // {
  //     title: "3E",
  //     img: ThreeE,
  //     //   hyperlinkTitle: "https://3eco.com/",
  //     description:
  //         "3E has been the trusted provider of intelligent compliance solutions for over 30 years, helping leading companies ensure safety and sustainability worldwide. Our solutions enable clients to seize market opportunities while meeting regulatory obligations and prioritizing safety.",
  //     link: "/",
  // },
  {
    title: "AI-Powered Digital Banking Hub",
    logo: BankAlfalah,
    image: BankAlfalahImage,
    hyperlinkTitle: "",
    description:
      "wAI collaborates with Bank Alfalah to introduce Pakistan's pioneering 24/7 Digital Banking Branch, enhancing accessibility and convenience.",
    link: "/",
  },
  {
    title: "Digitizing Retail Stores with Integrated POS systems",
    logo: FBR,
    image: FBRAnotherImage,
    hyperlinkTitle: "",
    description:
      "wAI and FBR Pakistan Partner to Revolutionize Retail Industry through Digital Transformation",
    link: "/",
  },
  {
    title: "Comprehensive User Authentication Suite",
    logo: Finja,
    image: FinjaImage,
    hyperlinkTitle: "",
    description:
      "An integrated solution expedites online account opening and verification processes, enhancing user experience and security.",
    link: "/",
  },
  {
    title: "Enhancing FBR's Diligence with Visual Analytics",
    logo: FBR,
    image: FBRImage,
    hyperlinkTitle: "",
    description:
      "wAI empowers the Federal Board of Revenue (FBR) to bolster production diligence through the integration of advanced visual analytics.",
    link: "/",
  },
  {
    title: "AI-Enabled Train Derailment Prediction",
    logo: MinistryOfRailways,
    image: MinistryOfRailwaysImage,
    hyperlinkTitle: "",
    description:
      "The Ministry of Railways collaborates with wAI to harness AI for the early prediction of potential train derailments, prioritizing safety.",
    link: "/",
  },
  {
    title: "POS Invoice verification through WhatsApp",
    logo: AskariBank,
    image: AskariBankImage,
    hyperlinkTitle: "",
    description:
      "Workcycle, wAI, and E-Ocean have collaborated to introduce a POS slop verification system accessible via WhatsApp.",
    link: "/",
  },
  // {
  //     title: "Genesys",
  //     hyperlinkTitle: "",
  //     logo: GenesysLogo,
  //     description: "Experience as a Service is about providing customers with personalized experiences that make them feel remembered, heard, and understood. It goes beyond technology, involving engagement across the organization to benefit customers, employees, and the business. By fostering connections and building trust, Experience as a Service earns customer loyalty over time through empathetic interactions and consistent delivery of the desired experience.",
  //     link: "/",
  // },
];

export const ourCompanies = [
  {
    title: "Fintes",
    icon: "fa-solid fa-briefcase text-primary-light text-[16px]",
    description:
      "Fintes is a Fintech company; looking to transition traditional banking onto a digital twin by starting from OCR cheque clearing.",
  },
  {
    title: "Machine & Languages",
    icon: "fa-solid fa-gears text-primary-light text-[16px]",
    description:
      "Machine & Languages has built products that are applied at scale at any organization looking to transition into the AI revolution",
  },
  {
    title: "ISSM",
    icon: "fa-solid fa-arrow-trend-up text-primary-light text-[16px]",
    description:
      "ISSM is the research art of wAI industries. Where state of the art research is conducted to serve future global AI needs.",
  },
  {
    title: "Workcycle",
    icon: "fa-solid fa-list-check text-primary-light text-[16px]",
    description:
      "Workcycle: is specifically a technology company aimed at serving SMEs; arming them with the right technological tools to improve operational efficiency",
  },
];

export const companiesInvestments = [
  {
    image: CSquare,
    alt: "CSquare",
    link: "https://csquare.co/",
  },
  {
    image: ISSM,
    alt: "ISSM",
    link: "https://issm.ai/",
  },
  {
    image: Workcycle,
    alt: "Workcycle",
    link: "",
  },
];

export const ourClientsData = [
  { image: Novu },
  { image: Plus21 },
  { image: GenesysLogo },
  { image: Eocean },
  { image: Alasila },
  { image: Ase },
  { image: TPS },
  { image: ExoScale },
  { image: Touchpoint },
  { image: Rototype },
  { image: Novu },
  { image: Plus21 },
  { image: GenesysLogo },
  { image: Eocean },
  { image: Alasila },
  { image: Ase },
  { image: TPS },
  { image: ExoScale },
  { image: Touchpoint },
  { image: Rototype },
];

export const useCasesData = [
  {
    tabTitle: "Case Studies",
    tabDesc: "Know your industrial fit with Wai Industries",
    heading: "Case Studies",
    useCases: [
      {
        useCaseTitle: "Manufacturing Optimizations",
        useCaseDescription:
          "Analyzing historical production data to identify inefficiencies, bottlenecks, and areas for improvement in the manufacturing process. This could involve predicting machine failures, optimizing maintenance schedules, or fine-tuning production workflows to maximize output and minimize downtime.",
        cardTitle: "Manufacturing Optimizations",
        cardQuestion: "",
        cardDescription:
          "Analyzing historical production data to identify inefficiencies, bottlenecks, and areas for improvement in the manufacturing process. This could involve predicting machine...",
        image: ManufacturingOpti,
      },
      {
        useCaseTitle: "Supply Chain Management",
        useCaseDescription:
          "Using advanced analytics to optimize inventory levels, forecast demand, and streamline logistics operations. This includes identifying patterns in supplier performance, predicting delivery times, and optimizing distribution networks to reduce costs and improve efficiency.",
        cardTitle: "Supply Chain Management",
        cardQuestion: "",
        cardDescription:
          "Using advanced analytics to optimize inventory levels, forecast demand, and streamline logistics operations. This includes identifying patterns in supplier performance, predicting delivery times, and optimizing distribution networks to reduce costs and improve efficiency.",
        image: SupplyChain,
      },
      {
        useCaseTitle: "Predictive Maintenance",
        useCaseDescription:
          "Employing data analytics to predict equipment failures before they occur, thereby minimizing unplanned downtime and reducing maintenance costs. By analyzing sensor data, historical maintenance records, and other relevant factors, organizations can implement proactive maintenance strategies to optimize equipment performance and extend asset lifecycles.",
        cardTitle: "Predictive Maintenance",
        cardQuestion: "",
        cardDescription:
          "Employing data analytics to predict equipment failures before they occur, thereby minimizing unplanned downtime and reducing maintenance costs. By analyzing sensor data, historical maintenance records, and other relevant factors, organizations can implement proactive maintenance strategies to optimize equipment performance and extend asset lifecycles.",
        image: PredictiveMaintainence,
      },
      {
        useCaseTitle: "Quality Control and Assurance",
        useCaseDescription:
          "Utilizing analytics to monitor and improve product quality throughout the production process. By analyzing data from sensors, inspection systems, and customer feedback, organizations can identify trends, detect defects early, and implement corrective actions to ensure consistent product quality and customer satisfaction.",
        cardTitle: "Quality Control and Assurance",
        cardQuestion: "",
        cardDescription:
          "Utilizing analytics to monitor and improve product quality throughout the production process. By analyzing data from sensors, inspection systems, and customer feedback, organizations can identify trends, detect defects early, and implement corrective actions to ensure consistent product quality and customer satisfaction.",
        image: QulityControl,
      },
      {
        useCaseTitle: "Energy Management",
        useCaseDescription:
          "Utilizing analytics to monitor and improve product quality throughout the production process. By analyzing data from sensors, inspection systems, and customer feedback, organizations can identify trends, detect defects early, and implement corrective actions to ensure consistent product quality and customer satisfaction.",
        cardTitle: "Energy Management",
        cardQuestion: "",
        cardDescription:
          "Utilizing analytics to monitor and improve product quality throughout the production process. By analyzing data from sensors, inspection systems, and customer feedback, organizations can identify trends, detect defects early, and implement corrective actions to ensure consistent product quality and customer satisfaction.",
        image: EnergyManagement,
      },
      {
        useCaseTitle: "Asset Performance Management",
        useCaseDescription:
          "Leveraging analytics to optimize the performance of industrial assets such as machinery, vehicles, and infrastructure. By analyzing data on asset usage, performance, and maintenance history, organizations can identify opportunities to improve asset reliability, optimize maintenance schedules, and minimize total cost of ownership.",
        cardTitle: "Asset Performance Management",
        cardQuestion: "",
        cardDescription:
          "Leveraging analytics to optimize the performance of industrial assets such as machinery, vehicles, and infrastructure. By analyzing data on asset usage, performance, and maintenance history, organizations can identify opportunities to improve asset reliability, optimize maintenance schedules, and minimize total cost of ownership.",
        image: AssetPerformance,
      },
      {
        useCaseTitle: "Risk Management and Compliance",
        useCaseDescription:
          "Using advanced analytics to assess and mitigate operational risks, ensure regulatory compliance, and enhance safety protocols. This includes analyzing historical incident data, identifying potential risk factors, and implementing predictive analytics models to anticipate and prevent potential issues before they escalate.",
        cardTitle: "Risk Management and Compliance",
        cardQuestion: "",
        cardDescription:
          "Using advanced analytics to assess and mitigate operational risks, ensure regulatory compliance, and enhance safety protocols. This includes analyzing historical incident data, identifying potential risk factors, and implementing predictive analytics models to anticipate and prevent potential issues before they escalate.",
        image: RiskManagement,
      },
      {
        useCaseTitle: "Customer Relationship Management (CRM)",
        useCaseDescription:
          "Analyzing customer data to gain insights into purchasing behavior, preferences, and satisfaction levels. By leveraging advanced analytics techniques such as predictive modeling and sentiment analysis, organizations can personalize marketing efforts, optimize sales strategies, and enhance overall customer experience.",
        cardTitle: "Customer Relationship Management (CRM)",
        cardQuestion: "",
        cardDescription:
          "Analyzing customer data to gain insights into purchasing behavior, preferences, and satisfaction levels. By leveraging advanced analytics techniques such as predictive modeling and sentiment analysis, organizations can personalize marketing efforts, optimize sales strategies, and enhance overall customer experience.",
        image: CRM,
      },
      {
        useCaseTitle: "Process Optimization",
        useCaseDescription:
          "Applying analytics to optimize various business processes, such as procurement, inventory management, and production planning. By analyzing historical data and identifying patterns, organizations can identify opportunities for streamlining workflows, reducing waste, and improving overall operational efficiency.",
        cardTitle: "Process Optimization",
        cardQuestion: "",
        cardDescription:
          "Applying analytics to optimize various business processes, such as procurement, inventory management, and production planning. By analyzing historical data and identifying patterns, organizations can identify opportunities for streamlining workflows, reducing waste, and improving overall operational efficiency.",
        image: ProcessOptimization,
      },
      {
        useCaseTitle: "Human Resources Management",
        useCaseDescription:
          "Using analytics to optimize workforce management, talent acquisition, and employee engagement initiatives. By analyzing HR data, organizations can identify trends, predict turnover risks, and tailor recruitment and retention strategies to attract and retain top talent.",
        cardTitle: "Human Resources Management",
        cardQuestion: "",
        cardDescription:
          "Using analytics to optimize workforce management, talent acquisition, and employee engagement initiatives. By analyzing HR data, organizations can identify trends, predict turnover risks, and tailor recruitment and retention strategies to attract and retain top talent.",
        image: HRM,
      },
    ],
  },
  {
    tabTitle: "Impact Studies",
    tabDesc: "Our products are creating impact",
    heading: "Impact Studies",
    useCases: [
      {
        useCaseTitle: "Custom Chatbots for Enterprise Data",
        useCaseDescription:
          "Streamline internal and external communications by annotating core enterprise data and deploying smart chatbots. Enhance customer support and management queries efficiently.",
        cardTitle: "AI Consultancy",
        cardQuestion: "What AI consulting services can you expect?",
        cardDescription:
          "Addepto offers unique AI consulting services that will help you realize how many opportunities come from implementing AI solutions into your business. Artificial Intelligence influences most industries, among the most popular are: retail, eCommerce, manufacturing, finance, healthcare, marketing, and gaming sector.",
        image: CustomChatbot,
      },
      {
        useCaseTitle: "Advanced Speech Bots for App Functions",
        useCaseDescription:
          "Improve user experience by integrating speech bots into your applications. From complex operations to simple tasks, enhance accessibility and usability for customers, facilitating seamless app interactions.",
        cardTitle: "App Functions",
        cardQuestion: "Advanced Speech Bots for App Functions",
        cardDescription:
          "Improve user experience by integrating speech bots into your applications. From complex operations to simple tasks, enhance accessibility and usability for customers, facilitating seamless app interactions.",
        image: SpeechBot,
      },
      {
        useCaseTitle: "AI-Powered Document Handling",
        useCaseDescription:
          "Digitize organizational documents effectively using advanced OCR techniques. Transition towards a digital age effortlessly by automating document processes and increasing efficiency.",
        cardTitle: "Document Handling",
        cardQuestion: "AI-Powered Document Handling",
        cardDescription:
          "Digitize organizational documents effectively using advanced OCR techniques. Transition towards a digital age effortlessly by automating document processes and increasing efficiency.",
        image: AiPowerd,
      },
      {
        useCaseTitle: "CCTV Analytics for FMCGs",
        useCaseDescription:
          "Harness the power of CCTV analytics coupled with Advanced OCR for FMCGs. Monitor key performance indicators in real-time, seamlessly integrating with core ERP systems for informed decision-making.",
        cardTitle: "FMCGs",
        cardQuestion: "CCTV Analytics for FMCGs",
        cardDescription:
          "Harness the power of CCTV analytics coupled with Advanced OCR for FMCGs. Monitor key performance indicators in real-time, seamlessly integrating with core ERP systems for informed decision-making.",
        image: CCTV,
      },
      {
        useCaseTitle: "Digital Customer Onboarding with OCR",
        useCaseDescription:
          "Revolutionize customer onboarding processes in financial organizations using OCR technology. Enable remote onboarding without physical presence, enhancing convenience and efficiency for both customers and institutions.",
        cardTitle: "OCR",
        cardQuestion: "Digital Customer Onboarding with OCR",
        cardDescription:
          "Revolutionize customer onboarding processes in financial organizations using OCR technology. Enable remote onboarding without physical presence, enhancing convenience and efficiency for both customers and institutions.",
        image: OCR,
      },
    ],
  },
  {
    tabTitle: "Consulting",
    tabDesc: "Helping you transition into the age of AI",
    heading: "Consulting",
    useCases: [
      {
        useCaseTitle: "Strategic AI Roadmap Development",
        useCaseDescription:
          "Partner with organizations to develop tailored AI strategies aligned with business objectives, identifying high-impact opportunities and recommending appropriate AI technologies and implementation approaches.",
        cardTitle: "AI Roadmap Development",
        cardQuestion: "",
        cardDescription:
          "Partner with organizations to develop tailored AI strategies aligned with business objectives, identifying high-impact opportunities and recommending appropriate AI technologies and implementation approaches.",
        image: StrategicAI,
      },
      {
        useCaseTitle: "Data Strategy and Governance",
        useCaseDescription:
          "Assist businesses in developing comprehensive data strategies and governance frameworks to ensure data quality, security, and compliance, enabling effective AI-driven decision-making and insights generation.",
        cardTitle: "Data Strategy and Governance",
        cardQuestion: "",
        cardDescription:
          "Assist businesses in developing comprehensive data strategies and governance frameworks to ensure data quality, security, and compliance, enabling effective AI-driven decision-making and insights generation.",
        image: DataStrategy,
      },
      {
        useCaseTitle: "AI Model Development and Deployment",
        useCaseDescription:
          "Collaborate with companies to design, develop, and deploy custom AI models tailored to specific business needs, leveraging machine learning, natural language processing, computer vision, and other AI techniques.",
        cardTitle: "AI Model Development and Deployment",
        cardQuestion: "",
        cardDescription:
          "Collaborate with companies to design, develop, and deploy custom AI models tailored to specific business needs, leveraging machine learning, natural language processing, computer vision, and other AI techniques.",
        image: AIModel,
      },
      {
        useCaseTitle: "AI Integration and Implementation",
        useCaseDescription:
          "Guide organizations in integrating AI solutions into existing workflows and systems, optimizing processes, and maximizing ROI by ensuring seamless adoption and integration with legacy infrastructure.",
        cardTitle: "AI Integration and Implementation",
        cardQuestion: "",
        cardDescription:
          "Guide organizations in integrating AI solutions into existing workflows and systems, optimizing processes, and maximizing ROI by ensuring seamless adoption and integration with legacy infrastructure.",
        image: AIIntegrations,
      },
      {
        useCaseTitle: "AI Ethics and Responsible AI",
        useCaseDescription:
          "Advise companies on ethical considerations and responsible AI practices, ensuring fairness, transparency, and accountability in AI algorithms and decision-making processes, and mitigating potential biases and risks.",
        cardTitle: "AI Ethics and Responsible AI",
        cardQuestion: "",
        cardDescription:
          "Advise companies on ethical considerations and responsible AI practices, ensuring fairness, transparency, and accountability in AI algorithms and decision-making processes, and mitigating potential biases and risks.",
        image: AiEthics,
      },
      {
        useCaseTitle: "AI Talent Development and Training",
        useCaseDescription:
          "Provide training and support to build internal AI capabilities, including upskilling employees, recruiting AI talent, and establishing AI Centers of Excellence (CoEs) to foster innovation and drive AI adoption.",
        cardTitle: "AI Talent Development and Training",
        cardQuestion: "",
        cardDescription:
          "Provide training and support to build internal AI capabilities, including upskilling employees, recruiting AI talent, and establishing AI Centers of Excellence (CoEs) to foster innovation and drive AI adoption.",
        image: AiTalent,
      },
      {
        useCaseTitle: "AI Performance Monitoring and Optimization",
        useCaseDescription:
          "Implement AI performance monitoring systems to track model performance, identify opportunities for optimization, and continuously improve AI solutions to meet evolving business needs and performance targets.",
        cardTitle: "AI Performance Monitoring and Optimization",
        cardQuestion: "",
        cardDescription:
          "Implement AI performance monitoring systems to track model performance, identify opportunities for optimization, and continuously improve AI solutions to meet evolving business needs and performance targets.",
        image: AIPerformance,
      },
      {
        useCaseTitle: "AI Risk Assessment and Management",
        useCaseDescription:
          "Conduct comprehensive risk assessments to identify potential risks and challenges associated with AI initiatives, developing mitigation strategies and contingency plans to minimize risks and ensure project success.",
        cardTitle: "AI Risk Assessment and Management",
        cardQuestion: "",
        cardDescription:
          "Conduct comprehensive risk assessments to identify potential risks and challenges associated with AI initiatives, developing mitigation strategies and contingency plans to minimize risks and ensure project success.",
        image: AIRisk,
      },
      {
        useCaseTitle: "AI Market Analysis and Opportunity Identification",
        useCaseDescription:
          "Conduct market research and analysis to identify emerging AI trends, technologies, and opportunities relevant to the industry, helping organizations stay competitive and capitalize on new AI-driven business models and revenue streams.",
        cardTitle: "Analysis and Opportunity Identification",
        cardQuestion: "",
        cardDescription:
          "Conduct market research and analysis to identify emerging AI trends, technologies, and opportunities relevant to the industry, helping organizations stay competitive and capitalize on new AI-driven business models and revenue streams.",
        image: AIMarket,
      },
      {
        useCaseTitle: "AI ROI Analysis and Value Measurement",
        useCaseDescription:
          "Evaluate the return on investment (ROI) of AI initiatives by quantifying the impact on key performance indicators (KPIs), such as revenue growth, cost reduction, and customer satisfaction, providing insights to optimize resource allocation and prioritize future investments.",
        cardTitle: "AI ROI Analysis and Value Measurement",
        cardQuestion: "",
        cardDescription:
          "Evaluate the return on investment (ROI) of AI initiatives by quantifying the impact on key performance indicators (KPIs), such as revenue growth, cost reduction, and customer satisfaction, providing insights to optimize resource allocation and prioritize future investments.",
        image: AIROI,
      },
    ],
  },
];

export const heroNewsRoomData = [
  {
    subject: "lorem ipsum",
    date: new Date(),
    title: "wAI partners with Psy to Develop ...",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
    image: AssetPerformance,
  },
  {
    subject: "lorem ipsum",
    date: new Date(),
    title: "wAI enters the Middle Eastern Market",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
    image: AssetPerformance,
  },
];

export const caseStudiesData = [
  {
    businessName: "Bank Alfalah",
    businessDescription:
      "ISSM collaborates with BAFL to introduce Pakistan's pioneering 24/7 Digital Banking Branch, enhancing accessibility and convenience.",
    businessCardInformation: [
      {
        heading: "Bank Alfalah",
        image: BankAlfalah,
        title: "AI-Powered Digital Banking Hub",
        description:
          "ISSM collaborates with BAFL to introduce Pakistan's pioneering 24/7 Digital Banking Branch, enhancing accessibility and convenience.",
        backgroundImage: UsecaseImage,
      },
      {
        heading: "Bank Alfalah",
        image: BankAlfalah,
        title: "Real Time Cheque Clearing in Pakistan",
        description:
          "State Bank of Pakistan, Bank Alfalah, Fintes and ISSM collaborated to bring real time cheque clearing, for the first time in Pakistan.",
        backgroundImage: UsecaseImage,
      },
      {
        heading: "Bank Alfalah",
        image: BankAlfalah,
        title: "AI-Powered Digital Banking Hub",
        description:
          "ISSM collaborates with BAFL to introduce Pakistan's pioneering 24/7 Digital Banking Branch, enhancing accessibility and convenience.",
        backgroundImage: UsecaseImage,
      },
    ],
  },
  {
    businessName: "FBR",
    businessDescription:
      "To check, supervise, ascertain, monitor, reconcile and properly enforce, in whole of Pakistan, the Withholding regime and other relevant provisions of the Income Tax Ordinance, 2001 and Sales Tax of 1990.",
    businessCardInformation: [
      {
        heading: "FBR",
        image: BankAlfalah,
        title: "Enhancing FBR's Diligence with Visual Analytics",
        description:
          "ISSM collaborates with BAFL to introduce Pakistan's pioneering 24/7 Digital Banking Branch, enhancing accessibility and convenience.",
        backgroundImage: UsecaseImage,
      },
      {
        heading: "FBR",
        image: BankAlfalah,
        title: "Enhancing FBR's Diligence with Visual Analytics",
        description:
          "State Bank of Pakistan, FBR, Fintes and ISSM collaborated to bring real time cheque clearing, for the first time in Pakistan.",
        backgroundImage: UsecaseImage,
      },
      {
        heading: "FBR",
        image: BankAlfalah,
        title: "Enhancing FBR's Diligence with Visual Analytics",
        description:
          "ISSM collaborates with BAFL to introduce Pakistan's pioneering 24/7 Digital Banking Branch, enhancing accessibility and convenience.",
        backgroundImage: UsecaseImage,
      },
    ],
  },
  {
    businessName: "Others",
    businessDescription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    businessCardInformation: [
      {
        heading: "Contactless Biometric - iVerify",
        title: "Comprehensive User Authentication Suite",
        description:
          "An integrated solution expedites online account opening and verification processes, enhancing user experience and security.",
        backgroundImage: UsecaseImage,
      },
      {
        heading: "Ministry of Railways Pakistan",
        title: "AI-Enabled Train Derailment Prediction",
        description:
          "The Ministry of Railways collaborates with ISSM to harness AI for the early prediction of potential train derailments, prioritizing safety.",
        backgroundImage: UsecaseImage,
      },
      {
        heading: "FBR",
        title: "Enhancing FBR's Diligence with Visual Analytics",
        description:
          "ISSM collaborates with BAFL to introduce Pakistan's pioneering 24/7 Digital Banking Branch, enhancing accessibility and convenience.",
        backgroundImage: UsecaseImage,
      },
    ],
  },
];

export const newsRoomTabData = [
  {
    tabTitle: "All",
    data: [
      {
        subject: "lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
        date: new Date(),
        title: "wAI partners with Psy to Develop ...",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
      {
        subject: "lorem ipsum",
        date: new Date(),
        title: "wAI acquires Plutus21",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
      {
        subject: "lorem ipsum",
        date: new Date(),
        title: "wAI enters the Middle Eastern Market",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
    ],
  },
  {
    tabTitle: "WAI Press",
    data: [
      {
        subject: "lorem ipsum lorem ipsum lorem ipsum",
        date: new Date(),
        title: "wAI partners with Psy to Develop ...",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
      {
        subject: "lorem ipsum",
        date: new Date(),
        title: "wAI acquires Plutus21",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
      {
        subject: "lorem ipsum",
        date: new Date(),
        title: "wAI enters the Middle Eastern Market",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
    ],
  },
  {
    tabTitle: "WAI Articles",
    data: [
      {
        subject: "lorem ipsum lorem ipsum",
        date: new Date(),
        title: "wAI partners with Psy to Develop ...",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
      {
        subject: "lorem ipsum",
        date: new Date(),
        title: "wAI acquires Plutus21",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
      {
        subject: "lorem ipsum",
        date: new Date(),
        title: "wAI enters the Middle Eastern Market",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
    ],
  },
  {
    tabTitle: "WAI News",
    data: [
      {
        subject: "lorem ipsum",
        date: new Date(),
        title: "wAI partners with Psy to Develop ...",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
      {
        subject: "lorem ipsum",
        date: new Date(),
        title: "wAI acquires Plutus21",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
      {
        subject: "lorem ipsum",
        date: new Date(),
        title: "wAI enters the Middle Eastern Market",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ",
        image: UsecaseImage,
      },
    ],
  },
];

export const teamData = [
  {
    name: "Ahsan Mashkoor",
    description:
      "Ahsan Mashkoor, our Co-Founder and Chairman, brings a wealth of experience to our organization. As the former founder and CEO of Csquare, he played a pivotal role in establishing the company as a leader in customer experience software, serving 80% of the Banking Industry in Pakistan. With a background that includes roles at Genesys and Terradata, Ahsan brings a deep understanding of industry dynamics and a proven track record of success in delivering innovative solutions. His leadership drives our commitment to excellence and ensures that we continue to push the boundaries of what is possible in empowering enterprises through AI-driven technologies.",
    designation: "Co-Founder / Chairman",
    image: Ahsan,
  },
  {
    name: "Abu Abdullah Ashar",
    description:
      "Abu Abdullah Ashar, the Founder and CEO of Wai, is a technical expert with extensive experience in the software industry, Ashar is known for his significant contributions to the software industry. His tenure at Synfox, where he led over 100 engineers in developing local software applications rivaling competitors like SAP HANA S4, highlights his leadership traits. Notably, Ashar's involvement in digitization initiatives with government entities and advisory roles in the National Assembly underscore his influential role in shaping policy and fostering collaboration. His visionary approach extends to addressing technology transfer challenges through AI-driven products at Wai, positioning the company as a global competitor against industry giants. Ashar's relentless pursuit of technical excellence and passion for innovation continues to drive wAI’s success in digitization processes worldwide.",
    designation: "Co-Founder / Chief Executive Officer / Chief Product Officer",
    image: Ashar,
  },
  {
    name: "Abdul Wahab Amin",
    description:
      "Abdul Wahab, serving as our Chief Product and Technical Officer, is an accomplished professional with a Master's degree in Computer Science, emphasizing Artificial Intelligence. His extensive experience includes developing cutting-edge products for the US Military, contributing to AI research, and conducting advanced AI training for Pakistan's premier institutions. He has also worked with global initiatives like WWF for the preservation of wildlife through the use of AI. Under his leadership, Wai has seen significant advancements in product innovation and technical strategy, solidifying our position in the machine learning domain and driving the company's expansion and success.",
    designation: "Co-Founder / Chief Product and Technical Officer",
    image: Wahab,
  },
];

export const partnersData = [
  {
    name: "Genesys",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    name: "Psy",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    name: "FBR",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    name: "Bank Alfalah",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    name: "Faysal Bank",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
];

export const globeConfig = {
  pointSize: 4,
  globeColor: "#990036",
  showAtmosphere: false,
  atmosphereColor: "#000000",
  atmosphereAltitude: 0.1,
  emissive: "#990036",
  emissiveIntensity: 0.1,
  shininess: 0.9,
  polygonColor: "rgba(255,255,255,0.7)",
  ambientLight: "#FF1B6C",
  directionalLeftLight: "#ffffff",
  directionalTopLight: "#ffffff",
  pointLight: "#ffffff",
  arcTime: 1000,
  arcLength: 0.9,
  rings: 1,
  maxRings: 3,
  initialPosition: { lat: 22.3193, lng: 114.1694 },
  autoRotate: true,
  autoRotateSpeed: 0.5,
};

export const colors = ["#ffffff", "#FF1B6C", "#990036"];

export const sampleArcs = [
  {
    order: 1,
    startLat: -19.885592,
    startLng: -43.951191,
    endLat: -22.9068,
    endLng: -43.1729,
    arcAlt: 0.1,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 1,
    startLat: 28.6139,
    startLng: 77.209,
    endLat: 3.139,
    endLng: 101.6869,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 1,
    startLat: -19.885592,
    startLng: -43.951191,
    endLat: -1.303396,
    endLng: 36.852443,
    arcAlt: 0.5,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 2,
    startLat: 1.3521,
    startLng: 103.8198,
    endLat: 35.6762,
    endLng: 139.6503,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 2,
    startLat: 51.5072,
    startLng: -0.1276,
    endLat: 3.139,
    endLng: 101.6869,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 2,
    startLat: -15.785493,
    startLng: -47.909029,
    endLat: 36.162809,
    endLng: -115.119411,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 3,
    startLat: -33.8688,
    startLng: 151.2093,
    endLat: 22.3193,
    endLng: 114.1694,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 3,
    startLat: 21.3099,
    startLng: -157.8581,
    endLat: 40.7128,
    endLng: -74.006,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 3,
    startLat: -6.2088,
    startLng: 106.8456,
    endLat: 51.5072,
    endLng: -0.1276,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 4,
    startLat: 11.986597,
    startLng: 8.571831,
    endLat: -15.595412,
    endLng: -56.05918,
    arcAlt: 0.5,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 4,
    startLat: -34.6037,
    startLng: -58.3816,
    endLat: 22.3193,
    endLng: 114.1694,
    arcAlt: 0.7,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 4,
    startLat: 51.5072,
    startLng: -0.1276,
    endLat: 48.8566,
    endLng: -2.3522,
    arcAlt: 0.1,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 5,
    startLat: 14.5995,
    startLng: 120.9842,
    endLat: 51.5072,
    endLng: -0.1276,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 5,
    startLat: 1.3521,
    startLng: 103.8198,
    endLat: -33.8688,
    endLng: 151.2093,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 5,
    startLat: 34.0522,
    startLng: -118.2437,
    endLat: 48.8566,
    endLng: -2.3522,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 6,
    startLat: -15.432563,
    startLng: 28.315853,
    endLat: 1.094136,
    endLng: -63.34546,
    arcAlt: 0.7,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 6,
    startLat: 37.5665,
    startLng: 126.978,
    endLat: 35.6762,
    endLng: 139.6503,
    arcAlt: 0.1,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 6,
    startLat: 22.3193,
    startLng: 114.1694,
    endLat: 51.5072,
    endLng: -0.1276,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 7,
    startLat: -19.885592,
    startLng: -43.951191,
    endLat: -15.595412,
    endLng: -56.05918,
    arcAlt: 0.1,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 7,
    startLat: 48.8566,
    startLng: -2.3522,
    endLat: 52.52,
    endLng: 13.405,
    arcAlt: 0.1,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 7,
    startLat: 52.52,
    startLng: 13.405,
    endLat: 34.0522,
    endLng: -118.2437,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 8,
    startLat: -8.833221,
    startLng: 13.264837,
    endLat: -33.936138,
    endLng: 18.436529,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 8,
    startLat: 49.2827,
    startLng: -123.1207,
    endLat: 52.3676,
    endLng: 4.9041,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 8,
    startLat: 1.3521,
    startLng: 103.8198,
    endLat: 40.7128,
    endLng: -74.006,
    arcAlt: 0.5,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 9,
    startLat: 51.5072,
    startLng: -0.1276,
    endLat: 34.0522,
    endLng: -118.2437,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 9,
    startLat: 22.3193,
    startLng: 114.1694,
    endLat: -22.9068,
    endLng: -43.1729,
    arcAlt: 0.7,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 9,
    startLat: 1.3521,
    startLng: 103.8198,
    endLat: -34.6037,
    endLng: -58.3816,
    arcAlt: 0.5,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 10,
    startLat: -22.9068,
    startLng: -43.1729,
    endLat: 28.6139,
    endLng: 77.209,
    arcAlt: 0.7,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 10,
    startLat: 34.0522,
    startLng: -118.2437,
    endLat: 31.2304,
    endLng: 121.4737,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 10,
    startLat: -6.2088,
    startLng: 106.8456,
    endLat: 52.3676,
    endLng: 4.9041,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 11,
    startLat: 41.9028,
    startLng: 12.4964,
    endLat: 34.0522,
    endLng: -118.2437,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 11,
    startLat: -6.2088,
    startLng: 106.8456,
    endLat: 31.2304,
    endLng: 121.4737,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 11,
    startLat: 22.3193,
    startLng: 114.1694,
    endLat: 1.3521,
    endLng: 103.8198,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 12,
    startLat: 34.0522,
    startLng: -118.2437,
    endLat: 37.7749,
    endLng: -122.4194,
    arcAlt: 0.1,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 12,
    startLat: 35.6762,
    startLng: 139.6503,
    endLat: 22.3193,
    endLng: 114.1694,
    arcAlt: 0.2,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 12,
    startLat: 22.3193,
    startLng: 114.1694,
    endLat: 34.0522,
    endLng: -118.2437,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 13,
    startLat: 52.52,
    startLng: 13.405,
    endLat: 22.3193,
    endLng: 114.1694,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 13,
    startLat: 11.986597,
    startLng: 8.571831,
    endLat: 35.6762,
    endLng: 139.6503,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 13,
    startLat: -22.9068,
    startLng: -43.1729,
    endLat: -34.6037,
    endLng: -58.3816,
    arcAlt: 0.1,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
  {
    order: 14,
    startLat: -33.936138,
    startLng: 18.436529,
    endLat: 21.395643,
    endLng: 39.883798,
    arcAlt: 0.3,
    color: colors[Math.floor(Math.random() * (colors.length - 1))],
  },
];

export const governmentHomePageData = {
  title: "Government",
  description:
    "wAI Industries is partnering with government institutions to enhance their operations and services. Through our AI embedded solutions, we facilitate operational enhancements and customer service. We collaborate with the Government to enhance transparency in retail, manufacturing, and other industries. This collaboration fosters digital transformation that is beneficial to all stakeholders.",
};

export const governmentDescription = {
  heading: "GOVERNMENT",
  title: "Federal Board of Revenue (FBR)",
  description:
    "For the Federal Board of Revenue (FBR), the adoption of the Alara chatbot signifies a monumental step in modernizing tax collection and administration in Pakistan. Alara has introduced an era of efficiency and accessibility, simplifying complex tax processes for both taxpayers and administrators through invoice verifications.",
};

export const governmentFbrCardData = [
  {
    title: "Digital Eye",
    description:
      "Wai Industries and FBR are embarking on the Digital Eye project, an effort to enhance transparency in retail and manufacturing sectors. By leveraging advanced technologies and Computer Vision, we aim to foster a more transparent, accountable, and efficient business environment. Automated tracking of production units, analyzing production lines across industries would lead to more transparent financial reporting.",
    image: DigitalEye,
  },
  {
    title: "Alara",
    description:
      "Wai Industries proudly introduces Xiva, an innovative AI powered solution tailored for FBR, revolutionizing the way FAQs, invoice verification, and feedback processes are handled. Customers can now verify retail invoices using WhatsApp chatbot of FBR provided by Wai Industries. Moreover, customers can directly use chatbot to get information on products, suggest improvements and lodge a complaint right from WhatsApp. With Alara, we're not just enhancing efficiency; we're transforming the user experience, making it more intuitive and responsive for everyone.",
    image: Alara,
  },
  {
    title: "Digital Twin",
    description:
      "In collaboration with FBR's Regional Tax Office, Wai Industries is working on a digital twin project that replicates manual operations in a digital platform. This initiative encompasses comprehensive document management, seamless approvals management, and the digitization of processes, setting a new standard for operational excellence in government services.",
    image: DigitalTwin,
  },
];

export const governmentPakistanCustoms = {
  heading: "",
  title: "Pakistan Customs",
  description:
    "Wai Industry is collaborating with Pakistan Customs to introduce AI powered WhatsApp Chatbot for customers to pose their questions directly. This initiative will breakdown complex custom laws and regulations for ease of information delivery for customers.",
};

export const governmentPakistanCustomsData = {
  image: Alara,
  title: "Alara",
  description:
    "Wai Industries is integrating Alara to Pakistan Customs, offering customers a platform where they can directly pose queries, ask about laws and regulations, custom fees and payments. This initiative is designed to streamline communication, facilitate smoother operations, and improve overall service delivery.",
};

export const governmentOthersData = {
  heading: "",
  title: "Others",
  description:
    "Wai Industries is working with other Government Institutions as well and has implemented a range of AI products and services. We are providing an end-end management platform for KWSB, automated mobile meter reading project for KE, and are working towards digitizing other institutes as well.",
};

export const governmentOthersDataArr = [
  {
    image: Kwsb,
    title: "KWSB",
    description:
      "Wai Industries is revolutionizing KWSB’s logistics and service businesses through a complete end-to-end management system. This leap towards automation and operational excellence underscores our commitment to driving innovation and efficiency within government service industries.",
  },
  {
    image: Ke,
    title: "KE",
    description:
      "Wai Industries has introduced an innovative solution for Karachi Electric (KE)  by implementing an automated mobile meter reading system using OCR technology. This advancement allows for the quick and accurate scanning of electricity meters. This technology not only streamlines operations but also enhances customer satisfaction by ensuring accuracy and transparency in electricity usage and billing.",
  },
];

export const financeHomePageData = {
  title: "Finance",
  description:
    "At Wai Industries, we are introducing a new era in financial technology, where artificial intelligence meets the dynamic needs of the finance industry. Our mission is to empower banks, financial institutions, and tax agencies with AI-driven solutions that redefine efficiency, enhance security, and elevate customer experience to unprecedented levels. Join us as we pave the way towards a smarter, more seamless financial world.",
};

export const financeDescription = {
  heading: "FINANCE",
  title: "Bank Alfalah",
  description:
    "In the era of digital banking, where customer service is paramount, Bank Alfalah has taken a significant leap forward by integrating the Alara chatbot into its customer service operations. This AI-powered assistant has enhanced the way customers interact with their bank, offering a seamless, 24/7 support system that caters to a wide array of banking needs with ease and efficiency.",
};

export const financeFbrCardData = [
  // {
  //     title: "(COEUS) Cheque Clearing",
  //     description:
  //         "In the evolving landscape of financial services, efficiency and security are paramount. Recognizing this need, Wai Industries proudly introduces Coeus, a revolutionary cheque clearing software designed to transform the traditional process of cheque verification and clearing into a seamless and expedited operation.",
  //     image: Coeus,
  // },
  {
    title: "Alara",
    description:
      "Wai Industries proudly introduces Xiva, an innovative AI powered solution tailored for FBR, revolutionizing the way FAQs, invoice verification, and feedback processes are handled. Customers can now verify retail invoices using WhatsApp chatbot of FBR provided by Wai Industries. Moreover, customers can directly use chatbot to get information on products, suggest improvements and lodge a complaint right from WhatsApp. With Alara, we're not just enhancing efficiency; we're transforming the user experience, making it more intuitive and responsive for everyone.",
    image: Alara,
  },
  {
    title: "Digital Facilitation Desk",
    description:
      "At the heart of the Digital Facilitation Desk lies its capability to log conversations in real-time, ensuring that every customer interaction is captured accurately and comprehensively. This not only enhances the quality of customer service by providing a detailed account of each interaction but also empowers customer care representatives with the information they need to make informed decisions quickly.",
    image: Dfd,
  },
];

export const financeFbrData = {
  heading: "",
  title: "Federal Board of Revenue (FBR)",
  description:
    "For the Federal Board of Revenue (FBR), the adoption of the Alara chatbot signifies a monumental step in modernizing tax collection and administration in Pakistan. Alara has introduced an era of efficiency and accessibility, simplifying complex tax processes for both taxpayers and administrators through invoice verifications.",
};

export const financeFbrOthersDataArr = [
  {
    image: Alara,
    title: "Alara",
    description:
      "Federal Board of Revenue (FBR) is leveraging Alara, a cutting-edge AI-powered solution from Wai Industries, to streamline tax administration processes. With Alara's complaint management module, the FBR has transformed the way it addresses taxpayer grievances, ensuring prompt resolution and improved satisfaction. Additionally, Alara's invoice verification feature has enhanced the accuracy and efficiency of tax collection efforts, enabling the FBR to identify discrepancies and mitigate potential revenue loss effectively. Experience the power of Alara in revolutionizing tax administration and fostering transparency and compliance.",
  },
  {
    image: DigitalEye2,
    title: "Digital Eye",
    description:
      "Wai Industries' Digital Eye is helping FBR get more visibility into the manufacturing and retail sectors through innovative digital solutions. By providing tools for counting production bags and verifying invoices, unique onboarding of offline activity in marquees - facilitated by OCR technology; allowing tax authorities to catalog sales data accurately, leading to more targeted tax collection efforts. Digital Eye enables tax authorities to streamline tax collection processes and combat tax evasion effectively.",
  },
];

export const militaryHomePageData = {
  title: "Military",
  description:
    "Wai’s automation technology optimizes training processes for the military, reducing costs and improving efficiency. This platform harnesses the power of Computer Vision (CV) technology and artificial intelligence (AI) to redefine the precision shooting experience in shooting ranges. Moreover, same model and concept can be applied to various other fields, including public events like the Olympic Games.",
};

export const militaryData = {
  image: SRA,
  title: "SRA",
  description:
    "Wai Industry collaborated with the Pakistan Army on their Shooting Range Automation project, utilizing Computer Vision and AI to enhance precision shooting. This technology provides instant feedback on shot accuracy by displaying the bullet's impact point to shooters on a screen right in front of the shooter. We have deployed our system that detects bullets and replicates its position on the display screen.  Beyond shooting ranges, its applications include enhancing Olympic shooting events, law enforcement and military training, security, and entertainment, promising improved accuracy and an immersive experience across various sectors.",
};

export const partnerData = [
  {
    image: GenesysLogo,
    title: "Genesys",
    description:
      "In partnership with Genesys, Wai Industries integrates Alara into leading banking CX solutions, elevating the customer experience through seamless service and support.",
  },
  {
    image: Eocean,
    title: "E-Ocean",
    description:
      "Through our partnership with E-Ocean, Wai Industries leverages their WhatsApp API and social media integration expertise to provide omnichannel, business-critical communication solutions, enhancing enterprise connectivity through multiple channels.",
  },
  {
    image: Ase,
    title: "Al Arabiya Systems Engineering",
    description:
      "In collaboration with ASE, Wai Industries has expanded its footprint across Saudi Arabia and the Middle Eastern region, with ASE serving as a key reseller and collaborative partner, bringing our innovative products closer to our clients in these areas.",
  },
  // {
  //     image: ThreeE,
  //     title: "3E",
  //     description:
  //         "With 3E as our technological partner in the USA, Wai Industries jointly offers consultancy and development services, crafting bespoke solutions that cater to the unique needs of our clients, fostering technological advancement and innovation.",
  // },
];

export const caseStudyStories = [
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={ManufacturingOpti}
            className=" object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={SupplyChain}
            className=" object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={PredictiveMaintainence}
            className="object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={QulityControl}
            className=" object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={EnergyManagement}
            className=" object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={AssetPerformance}
            className="object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={RiskManagement}
            className="object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={CRM}
            className=" object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={ProcessOptimization}
            className=" object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={HRM}
            className="object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
];

export const impactStudyStories = [
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            className="object-contain"
            src={CustomChatbot}
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            className="object-contain"
            src={SpeechBot}
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            className=" object-contain"
            src={AiPowerd}
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            className="object-contain"
            src={CCTV}
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            className=" object-contain"
            src={OCR}
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
];

export const consultingStories = [
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={StrategicAI}
            className="object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={DataStrategy}
            className=" object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={AIModel}
            className="object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={AIIntegrations}
            className="object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={AiEthics}
            className=" object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={AiTalent}
            className="object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={AIPerformance}
            className=" object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={AIRisk}
            className=" object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            src={AIMarket}
            className=" object-contain"
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
  {
    content: ({ action, isPaused }) => {
      return (
        <div style={contentStyle}>
          <img
            style={image}
            className="object-contain"
            src={AIROI}
            alt="storiesImage"
          ></img>
        </div>
      );
    },
  },
];

const image = {
  display: "block",
  maxWidth: "100%",
  borderRadius: 4,
};

const contentStyle = {
  background: "#FBFBFB",
  width: "100%",
  color: "#FBFBFB",
  justifyContent: "center",
  alignItems: "center",
};
