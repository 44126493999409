import React from 'react';
import { socialCircle } from '../../styles/styles';
import { Link } from 'gatsby';
import ButtonPrimary from '../ui/ButtonPrimary';

const Footer = () => {
    return (
        <div className='bg-[#131416]'>
           
            <footer>
                <div className="mx-auto max-w-screen-xl space-y-8 px-4 pt-16 sm:px-6 lg:space-y-16 lg:px-8">
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                        <div>
                            <div className='my-14 md:my-0 sm:w-[90%] w-[100%]'>
                                <h3 className='text-[26px] font-semibold text-white'>Subscribe</h3>
                                <p className='text-[14px] mt-3 mb-3'>
                                    <span className='!text-white font-thin tracking-wide'>Subscribe to our newsletter to stay up to date about our partners, projects and AI research</span>
                                </p>
                                <input type="email" name="email" id="email" className="rounded-md font-plusJakartaSans bg-gray-50 border border-gray-300 text-black font-normal text-[13px] my-5 focus:ring-blue-500 focus:border-blue-500 block w-[100%] px-3.5 py-2.5 dark:placeholder-gray-400" placeholder="johnDoe@gmail.com" required />
                                <ButtonPrimary btnTitle={"Subscribe now"} isPrimary />
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4">
                            <div>
                                <p className="font-bold text-white text-[18px]">Explore</p>
                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <Link to='/' className="!text-white font-thin transition hover:opacity-75">Home</Link>
                                    </li>
                                    <li>
                                        <Link to='/partners' className="!text-white font-thin transition hover:opacity-75">Partners</Link>
                                    </li>
                                    <li>
                                        <Link to='/leadership' className="!text-white font-thin transition hover:opacity-75">Leadership</Link>
                                    </li>
                                    <li>
                                        <Link to='/contact-us' className="!text-white font-thin transition hover:opacity-75">Contact Form</Link>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <p className="font-bold text-white text-[18px]">Industries</p>
                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <Link to='/industries/finance' className="!text-white font-thin transition hover:opacity-75">Finance</Link>
                                    </li>
                                    <li>
                                        <Link to='/industries/government' className="!text-white font-thin transition hover:opacity-75">Government</Link>
                                    </li>
                                    <li>
                                        <Link to='/industries/military' className="!text-white font-thin transition hover:opacity-75">Military</Link>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <p className="font-bold text-white text-[18px]">Contact Us</p>
                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <p className="!text-white font-thin transition ">Email: hello@issm.ai</p>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <p className="font-bold text-white text-[18px]">Visit Us</p>
                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <p className="!text-white font-thin transition ">SAI capital, Lahore, Pakistan. </p>
                                    </li>
                                    <li>
                                        <p className="!text-white font-thin transition ">Csquare, Karachi, Pakistan.</p>
                                    </li>
                                    <li>
                                        <p className="!text-white font-thin transition ">USA</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between items-end flex-wrap">
                        {/* contact us */}
                        <div>
                            {/* <div className='mb-14'>
                                <h3 className='text-[26px] font-semibold'>Contact Us</h3>
                                <p className='text-[15px] mt-3 mb-3'>
                                    <i className="fa-solid fa-envelope mr-4"></i> <a href='mailto:hello@issm.ai' className='text-primary-light'><u>hello@issm.ai</u></a>
                                </p>
                            </div> */}

                            {/* visit us */}
                            {/* <div>
                                <h3 className='text-[26px] font-semibold mb-2'>Visit Us</h3>
                                <div className='flex items-start gap-14'>
                                    <div className='py-2'>
                                        <p className='text-[14px] my-3'>
                                            <span className='font-semibold leading-9'>Pakistan</span> <br />
                                            <span className='!text-[14px] text-primary-lighter'>Csquare, Karachi</span><br />
                                            <span className='!text-[14px] text-primary-lighter'>SAI Capital, Lahore </span><br />
                                            <span className='!text-[14px] text-primary-lighter'>Daftarkhwan, Street 7, I-10/3, Islamabad </span>
                                        </p>
                                    </div>
                                    <div className='py-2'>
                                        <p className='text-[14px] my-3'>
                                            <span className='font-semibold leading-9'>USA</span> <br />
                                            <span className='!text-[14px] text-primary-lighter'></span>
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className='w-[100%] md:w-[30%] my-14 md:my-0'>
                            <h3 className='text-[26px] font-semibold'>Subscribe</h3>
                            <p className='text-[14px] mt-3 mb-3'>
                                <span className='text-primary-lighter'>Subscribe to our newsletter to stay up to date about our partners, projects and AI research</span>
                            </p>
                            <input type="email" name="email" id="email" className="rounded-md font-plusJakartaSans bg-gray-50 border border-gray-300 text-black font-normal text-[13px] my-5 focus:ring-blue-500 focus:border-blue-500 block w-[100%] px-3.5 py-2.5 dark:placeholder-gray-400" placeholder="johnDoe@gmail.com" required />
                            <ButtonPrimary btnTitle={"Subscribe now"} isPrimary />
                        </div> */}
                    </div>
                </div>
            </footer>

            <footer>
                <div className="w-full max-w-screen-xl mx-auto">
                    <hr className='border-zinc-700' />
                    <div className="flex flex-wrap items-center justify-center sm:justify-between p-4 md:py-6">
                        <p className="!text-white font-thin transition text-[14px] my-4 sm:my-0"> © 2024 wAI Advanced Industries. All rights reserved</p>
                        <div className='flex items-center my-4 sm:my-0'>
                            <div className={`${socialCircle}`}>
                                <i className="fa-brands fa-facebook-f text-gray-300 text-[20px]"></i>
                            </div>
                            <div className={`${socialCircle}`}>
                                <i className="fa-brands fa-instagram text-gray-300 text-[20px]"></i>
                            </div>
                            <div className={`${socialCircle}`}>
                                <i className="fa-brands fa-twitter text-gray-300 text-[20px]"></i>
                            </div>
                            <div className={`${socialCircle}`}>
                                <i className="fa-brands fa-linkedin text-gray-300 text-[20px]"></i>
                            </div>
                        </div>
                        <div className='flex items-center my-4 sm:my-0'>
                            <Link to='/privacy-policy' className="text-[14px] hover:underline me-4 md:me-6 text-white">Privacy Policy</Link>
                            <p className='me-4 md:me-6 text-gray-600'>|</p>
                            <Link to='/terms-conditions' className="text-[14px] hover:underline me-4 md:me-6 text-white">Terms & Conditions</Link>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    );
};

export default Footer;